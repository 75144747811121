import React from 'react';
import { Link } from 'gatsby';

const NavBar = ({ toggleNavbar, isActive, openPaymentPortalModal }) => (
  <nav className="navbar is-primary is-fixed-top" aria-label="main navigation">
    <div className="navbar-brand">
      <Link to="/" className="navbar-item">
        <strong>Lyons Road Trash</strong>
      </Link>
      <button
        className={`button is-primary navbar-burger ${
          isActive ? 'is-active' : ''
        }`}
        data-target="navMenu"
        onClick={toggleNavbar}
      >
        <span />
        <span />
        <span />
      </button>
    </div>
    <div className={`navbar-menu ${isActive ? 'is-active' : ''}`} id="navMenu">
      <div className="navbar-end">
        <Link className="navbar-item" to="/about">
          About
        </Link>
        <Link className="navbar-item" to="/pricing">
          Pricing
        </Link>
        <Link className="navbar-item" to="/contact">
          Contact Us
        </Link>
        <div className="navbar-item">
          <div className="field is-grouped">
            <p className="control">
              <button
                type="button"
                className={`button ${
                  isActive ? 'is-primary' : 'is-white'
                } is-outlined`}
                onClick={openPaymentPortalModal}
              >
                Online Payment Portal
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  </nav>
);

export default NavBar;
