import React, { Component, Fragment } from 'react';
import Swal from 'sweetalert2';
import Helmet from 'react-helmet';
import '../../assets/sass/styles.sass';
import config from '../../../config';
import NavBar from '../NavBar';
import Footer from '../Footer';

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = { isActive: false };
    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.openPaymentPortalModal = this.openPaymentPortalModal.bind(this);
  }

  toggleNavbar() {
    this.setState({ isActive: !this.state.isActive });
  }

  openPaymentPortalModal() {
    Swal.fire({
      icon: 'info',
      title: 'Online Payment Portal',
      html: `
        <p style="margin-bottom: 1rem">TrashFlow is Lyons Road Trash's online payment portal located at <a href="https://trashbilling.com" rel="noopenner noreferrer" target="_blank">https://trashbilling.com</a>.</p>

        <p style="margin-bottom: 1rem">You can use TrashFlow to make online payments, view your billing history, setup automatic
        payments, and more.</p>

        <p>If you have any questions please call our office at (315)-789-2979.</p>
      `,
      showCloseButton: true,
      showCancelButton: true,
      showConfirmButton: true,
      focusConfirm: true,
      confirmButtonText: 'Go to Online Payment Portal',
      cancelButtonText: 'Stay here',
    }).then(result => {
      if (result.value) {
        window.location = 'https://trashbilling.com';
      }
    });
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>{config.siteTitle}</title>
          <meta name="description" content={config.siteDescription} />
        </Helmet>
        <NavBar
          isActive={this.state.isActive}
          toggleNavbar={() => this.toggleNavbar()}
          openPaymentPortalModal={this.openPaymentPortalModal}
        />
        <Fragment>{this.props.children}</Fragment>
        <Footer />
      </Fragment>
    );
  }
}

export default Layout;
