import React from 'react';
import config from '../../../config';

const Footer = () => {
  return (
    <footer className="footer" style={{ backgroundColor: '#fff' }}>
      <div className="container">
        <div className="content has-text-centered">
          <p>{config.copyright}</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
