module.exports = {
  siteTitle: 'Lyons Road Trash', // Site title.
  siteTitleAlt: 'Business', // Alternative site title for SEO.
  siteLogo: '/icons/icon.jpg', // Logo used for SEO and manifest.
  siteUrl: 'https://lyons-road-trash.netlify.com', // Domain of your website without pathPrefix.
  pathPrefix: '/', // Prefixes all links. For cases when deployed to example.github.io/lyons-road-trash/.
  siteDescription: '', // Website description used for RSS feeds/meta description tag.
  siteRss: '/rss.xml',
  siteFBAppID: '', // FB Application ID for using app insights
  googleTagManagerID: '', // GTM tracking ID.
  userName: '',
  userTwitter: '',
  userLocation: '',
  userDescription: '',
  copyright: 'Copyright © Lyons Road Trash 2019. All Rights Reserved.', // Copyright string for the footer of the website and RSS feed.
  themeColor: '#cc3b49', // Used for setting manifest and progress theme colors.
  backgroundColor: '#ffffff', // Used for setting manifest background color.
};
